import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid2'
import type { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import type { DateTime as DateTimeType } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import PageHeader from '@/components/layouts/PageHeader'
import TabPanel from '@/components/layouts/TabPanel'
import { ONE_MONTH_AGO, TODAY } from '@/constants/dateTime'
import { useAlertContext } from '@/contexts/AlertContext'
import ForecastChart from '@/features/bessDashboard/components/ForecastChart'
import HistoricalPriceOverviewChart from '@/features/bessDashboard/components/HistoricalPriceOverviewChart'
import PricingTable from '@/features/bessDashboard/components/PricingTable'
import SingleInputDateRangePicker from '@/features/bessDashboard/components/SingleInputDateRangePicker'
import { MARKET_PROGRAM_NAMES } from '@/features/bessDashboard/constants'
import { useForecastQuery } from '@/features/bessDashboard/hooks/usePriceForecastQuery'
import { getTimeZoneString } from '@/features/bessDashboard/utils/chart'
import { formatTimestampToUtcIso } from '@/features/bessDashboard/utils/utils'

function BessDashboardIndex() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const [startTime, setStartTime] = useState<DateTimeType>(ONE_MONTH_AGO)
  const [endTime, setEndTime] = useState<DateTimeType>(TODAY)

  const {
    data: forecastsData,
    isError: forecastError,
    isLoading: forecastLoading,
  } = useForecastQuery(formatTimestampToUtcIso(startTime), formatTimestampToUtcIso(endTime.plus({ hours: 23 })))

  const isLoading = forecastLoading
  const isError = forecastError

  const [selectedMarketProgram, setSelectedMarketProgram] = useState<string>('FCR-D DOWN')
  const [selectedTab, setSelectedTab] = useState(0)

  const handleMarketProgramChange = (evt: SelectChangeEvent) => {
    const selectedValue = evt.target.value
    setSelectedMarketProgram(selectedValue)
  }

  useEffect(() => {
    if (isError) {
      pushAlert({
        message: t('bess_dashboard.get_forecast_generic_error_message'),
        severity: 'error',
      })
    }
  }, [isError])

  return (
    <>
      <PageHeader
        appBarContent={
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            sx={{ justifyContent: 'space-between', width: '100%' }}
          >
            <CustomSelectField
              id="market-program"
              label="Market Program"
              options={MARKET_PROGRAM_NAMES}
              size="medium"
              sx={{ minWidth: 220 }}
              value={selectedMarketProgram}
              variant="outlinedWhite"
              onChange={handleMarketProgramChange}
            />
            <SingleInputDateRangePicker
              endTime={endTime}
              setEndTime={setEndTime}
              setStartTime={setStartTime}
              startTime={startTime}
            />
          </Box>
        }
        breadcrumbItems={[]}
        pageTitle="Finland hourly market forecasts for BESS"
      />

      {isLoading ? (
        <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '400px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 2, mb: 3, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs
              aria-label="bidding tabs"
              indicatorColor="secondary"
              textColor="secondary"
              value={selectedTab}
              onChange={(_, newValue) => setSelectedTab(newValue)}
            >
              <Tab label="Price Forecasts" />
              <Tab label="Volume Forecasts" />
              <Tab label="Price-volume interaction" />
              <Tab label="Bid Pricing Table" />
            </Tabs>

            <Typography color="textSecondary" variant="body2">
              Times displayed in {getTimeZoneString()}
            </Typography>
          </Grid>

          <TabPanel index={0} value={selectedTab}>
            <ForecastChart
              endTime={endTime}
              filterType="capacity_price_per_MW_h_HOURLY"
              forecastValues={forecastsData ?? []}
              infoText={`Projected capacity price for ${selectedMarketProgram}. Updated daily with a 48-hour forecast, enabling precise adjustments to enhance your bidding strategy. High and low P80/P50 values represent confidence intervals: P80 shows a conservative range where prices are expected to fall 80% of the time, while P50 provides a balanced median range, expected to be met 50% of the time.`}
              isFetching={isLoading}
              isLoading={isLoading}
              marketProgram={selectedMarketProgram}
              startTime={startTime}
              title="Forecasted & Actual Capacity Price Forecasts"
              unit="€/MW,h"
            />
          </TabPanel>
          <TabPanel index={1} value={selectedTab}>
            <Box display="flex" flexDirection="row" gap={4} justifyContent="space-between">
              <Box flex={1}>
                <ForecastChart
                  endTime={endTime}
                  filterType="procured_capacity_MW_HOURLY"
                  forecastValues={forecastsData ?? []}
                  infoText={`Projected Hourly Procured volume for ${selectedMarketProgram}. Updated daily with a 48-hour forecast. High and low P80/P50 values represent confidence intervals: P80 shows a conservative range where values are expected to fall 80% of the time, while P50 provides a balanced median range, expected to be met 50% of the time.`}
                  isFetching={isLoading}
                  isLoading={isLoading}
                  marketProgram={selectedMarketProgram}
                  startTime={startTime}
                  title="Hourly Procured Volume Forecasts"
                  unit="MW"
                />
              </Box>
              <Box flex={1}>
                <ForecastChart
                  endTime={endTime}
                  filterType="offered_capacity_MW_HOURLY"
                  forecastValues={forecastsData ?? []}
                  infoText={`Projected Hourly Offered volume for ${selectedMarketProgram}. Updated daily with a 48-hour forecast. High and low P80/P50 values represent confidence intervals: P80 shows a conservative range where values are expected to fall 80% of the time, while P50 provides a balanced median range, expected to be met 50% of the time.`}
                  isFetching={isLoading}
                  isLoading={isLoading}
                  marketProgram={selectedMarketProgram}
                  startTime={startTime}
                  title="Hourly Offered Volume Forecasts"
                  unit="MW"
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel index={2} value={selectedTab}>
            <Stack spacing={1}>
              <HistoricalPriceOverviewChart
                data={forecastsData ?? []}
                endTime={endTime}
                filterType="capacity_price_per_MW_h_HOURLY"
                infoText="Per hour Distribution of market prices"
                isFetching={isLoading}
                isLoading={isLoading}
                startTime={startTime}
                title="Hourly Market Price Distribution"
                unit="€/MW,h"
              />
            </Stack>
          </TabPanel>
          <TabPanel index={3} value={selectedTab}>
            <PricingTable marketProgram={selectedMarketProgram} />
          </TabPanel>
        </>
      )}
    </>
  )
}

export default BessDashboardIndex
